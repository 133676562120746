import React, { Component } from "react";
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';

class Services extends Component {
  render() {
    return (
      // Services
      <section className="page-section" id="services">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 text-center">
              <h2 className="section-heading text-uppercase">Cleaning Services</h2>
              <h3 className="section-subheading text-muted">
                We provide an array of scheduled cleanings, deep cleanings, emergency cleanings and additional cleaning services
              </h3>
            </div>
          </div>
          <div className="row text-center">
            <div className="col-md-4">
              <a href="https://app.zenmaid.com/book/mean-deep-clean">
              <span className="fa-stack fa-4x">
                <i className="fas fa-circle fa-stack-2x text-primary"  />
                <i  className="fas fa-home fa-stack-1x fa-inverse"  />
              </span>
              <h4  className="service-heading">Home Cleaning
                </h4>
                </a>
             
              <p className="text-muted">
              <ul>
              Cleaning rates as low as $79 per week. Mean Deep Clean provides organic cleaning service in New Jersey & New York. Just choose the organic button on our booking page. Easy online booking and scheduling. All cleaning supplies are included! 
         
              </ul>
              </p>
            </div>
            <div className="col-md-4">
            <a href="https://app.zenmaid.com/book/mean-deep-clean">

              <span className="fa-stack fa-4x">
                <i className="fas fa-circle fa-stack-2x text-primary" />
                <i className="fas fa-users fa-stack-1x fa-inverse" />
              </span>
              <h4 className="service-heading">Senior Citizen & Disabled</h4>
              </a>
              <p className="text-muted">
We provide high quality and affordable cleaning services to our Seniors and anyone with disabilites. Mean Deep Clean offers a special $89 deep cleaning, perfect gift for a loved one who needs help around the house. Mean Deep Clean answers all calls 24/7.
              </p>
            </div>
            <div className="col-md-4">
            <a href="https://app.zenmaid.com/book/mean-deep-clean">
              <span className="fa-stack fa-4x">
                <i className="fas fa-circle fa-stack-2x text-primary" />
                <i className="fas fa-paint-roller fa-stack-1x fa-inverse" />
              </span>
              <h4 className="service-heading">Power & Window Washing</h4>
              </a>
              <p className="text-muted">
                Mean Deep Clean offers residential and commercial window cleaning ensuring the job is always done right, and in the safest way possible. Power washing all homes for $250 every and any day! Get a power wash for your home in NJ or NY today. 
              </p>
            </div>
          </div>

          <div className="row text-center">
            <div className="col-md-4">
            <a href="https://app.zenmaid.com/book/mean-deep-clean">
              <span className="fa-stack fa-4x">
                <i className="fas fa-circle fa-stack-2x text-primary" />
                <i className="fas fa-hotel fa-stack-1x fa-inverse" />
              </span>
              <h4 className="service-heading">Commercial Cleanings</h4>
              </a>
              <p className="text-muted">
              <ul>
              <li>Licensed & Insured</li>
                <li>Provide daily excellence and a fresh enviorment to your clients.</li>
                <li> Property Maintenance. </li>
                <li>Available 24/7 to serve all your business needs. </li>
                </ul>
              </p>
            </div>
            <div className="col-md-4">
            <a href="https://app.zenmaid.com/book/mean-deep-clean">
              <span className="fa-stack fa-4x">
                <i className="fas fa-circle fa-stack-2x text-primary" />
                <i className="fas fa-box-open fa-stack-1x fa-inverse" />
              </span>
              <h4 className="service-heading">Move-in/Move-out </h4>
              </a>
              <p className="text-muted">
              Mean Deep Clean provides thorough move in/move out cleanings that are designed to prepare a home for a new tenant. From top to bottom, we’ll clean and sanitize leaving your home a dust-free. Just choose the move in/ move out extra in our booking page. 
              </p>
            </div>
            <div className="col-md-4">
            <a href="https://app.zenmaid.com/book/mean-deep-clean">
              <span className="fa-stack fa-4x">
                <i className="fas fa-circle fa-stack-2x text-primary" />
                <i className="fas fa-scroll fa-stack-1x fa-inverse" />
              </span>
              <h4 className="service-heading">Carpet Cleaning</h4>
              </a>
              <p className="text-muted">
              Mean Deep Clean is your #1 choice when it comes to carpet, rug and upholstery care. We offer professional Carpet, Rug and Upholstery Cleaning Services to residents in New Jersey and New York.
              </p>
            </div>
          </div>

          <div className="col-lg-12 text-center">
                  <div id="success" />
                  <div>
                  <a
                    id="sendMessageButton"
                    className="btn btn-success btn-xl text-uppercase" href="https://app.zenmaid.com/book/mean-deep-clean" >
                    Free Quotes
                  </a>
                  </div>

          </div>
        </div>
      </section>
    );
  }
}

export default Services;
