import React, { Component } from "react";

class Main extends Component {

  render() {
    // Header
    return (

      <header className="masthead">
      <div className="container">
        <div className="intro-text">
        <div className="intro-heading text-uppercase">
          <h1>
            Open 7 days a week for all of your cleaning needs
            </h1>

            
             </div>
             <div>

          <div className="intro-lead-in">
           <h3>
           “A clean environment elevates a clear mind.” -Confucius
             </h3>
          </div>
          </div>


          <a
            className="btn btn-primary btn-xl text-uppercase slide"
            href="https://app.zenmaid.com/book/mean-deep-clean"
            target="_blank"
          >
           Book Your Cleaning Now
          </a>
        </div>
      </div>
    </header>
    );
  }
}

export default Main;
